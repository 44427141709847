#popover-schedule {
    max-width: 100%;
    width: 30 0px;
    margin: 0;
    padding: 0;
}
.scheduleContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.titleSchedule h3 {
    color: #9a9a9a;
    font-size: 18px;
    margin: 10px auto;
}

.bodySchedule {
    border-bottom: 2px solid rgba(135,135,135,0.3);
    border-top: 2px solid rgba(135,135,135,0.3);
}

.footerSchedule {
    margin-top: 10px;
}

.bodySchedule .scheduleAmount {
    color: #8a8a8a;
    font-size: 18px;
}

.bodySchedule .schedulesInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.bodySchedule .schedulesInfo p{ 
    margin: 0 0 0 10px;
    padding: 0;
}

.scheduleContainer .bi {
    color: #64c2c8;
    font-size: 2em;
}

.footerSchedule .linkVariant {
  color: white;
  background: #17A2BB;
  padding:7px;
  border-radius: 3px;
}

.iconButton {
    height: 32px;
    width: 32px;
    margin-right: 20px;
    margin-top: 9px;
}