.inputPasswordContainer {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 48px;
  width: 320px;
  align-items: center;
}

.inputPasswordContainer .backIcon {
  margin: 0px 10px;
}

.inputPasswordContainer .inputPassword {
  border: none;
  outline: none;
  width: 100%;
  font-size: 16px;
  padding-left: 10px;
  font-family: Source Sans Pro;
  color: #7b7b7b;
}
