.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input,
.generalData,
.companyDatalabel,
.yor_companyData,
.text-muted,
.css-1ixds2g {
  color: #7b7b7b !important;
}

.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #aeaeae !important;
}

.btn-voltar-company-data {
  width: 140px;
  height: 42px;
  color: #fff;
  background-color: #7b7b7b;
  margin-top: 40px;
  font-size: 16px !important;
}

.alert-success {
  background-color: #0cba90;
}

.btn-voltar-company-data:not(:disabled):not(.disabled):hover {
  background-color: #fff !important;
  border: 1px solid #7b7b7b;
}

.btn-voltar-company-data:not(:disabled):not(.disabled):hover a {
  color: #7b7b7b !important;
}

.div-modal-company-data h2 {
  color: #7b7b7b;
  font-family: Source Sans Pro;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  margin-top: -20px;
}

.btn-modal-company-data-sim button {
  background-color: #58c5c6;
  color: #fff;
  border: 1px solid #58c5c6;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
}

.btn-modal-company-data-sim button:hover {
  background-color: #fff;
  color: #58c5c6;
  border: 1px solid #58c5c6;
}

.btn-modal-company-data-nao button {
  background-color: #7b7b7b;
  color: #fff;
  border: 1px solid #7b7b7b;
  font-size: 16px;
  font-weight: 600;
}

.btn-modal-company-data-nao button:hover {
  background-color: #fff;
  color: #7b7b7b !important;
  border: 1px solid #7b7b7b;
}
