@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

h3 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  font-size: 20px;
  margin-top: 20px;
  color: #212121;
}

p {
  font-family: "Source Sans Pro", sans-serif;
  color: #7b7b7b;
}

.card-agende-consultoria {
  width: 100%;
  height: 97%;
}

.logo-agende-consultoria {
  height: 60px;
}

.saiba-mais {
  cursor: pointer;
  color: #58c5c6;
}

.saiba-mais:hover {
  color: #17a2b8;
}
