#list-videos {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.visualizations {
  font-size: 0.8rem;
}

#description {
  width: 70%;
}

.category_card {
  width: 360px !important;
  height: 240px !important;
}

@media only screen and (max-width: 1400px) {
  #list-videos {
    flex-direction: row;
    flex-wrap: wrap;
  }

  #learning {
    margin-top: 5vh;
  }
}

@media only screen and (max-width: 512px) {
  .current-video {
    width: 360px;
    height: 360px;
  }
}
