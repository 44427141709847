* {
  font-family: "Source Sans Pro";
  font-weight: 600;
}

:root {
  --backgroud-primary: #fff;
}

body {
  background: var(--backgroud-primary);
}

::placeholder {
  font-weight: 500 !important;
  opacity: 50% !important;
}

.icon_hover :hover {
  cursor: pointer;
}
/* html, body {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
} */
