@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #212121;
}

p {
  font-family: "Source Sans Pro", sans-serif;
}

.modalBody .price {
  font-size: 25px;
  color: #878787;
}

.modalBody .getNumber {
  display: flex;
  flex-direction: column;
}
.modalBody .foot {
  border-top: 2px solid rgba(135, 135, 135, 0.3);
}

.modalBody .getNumber .copyNumber {
  width: 210px;
  align-self: flex-end;
  margin-bottom: 10px;
}

.buttonLeft {
  align-self: flex-end;
}

.barCode {
  background: none;
  border: none;
}

.barCode:focus {
  outline: none;
}

.monthWidth {
  width: 10vw;
}

/* .card-das {
  width: 100%;
} */

table th {
  width: 460px;
  text-align: left;
  font-size: 20px;
  color: #7b7b7b;
}

table tr {
  text-align: left;
  border-bottom: 1px solid #ebebeb;
  color: #7b7b7b;
}

table tr td {
  padding: 15px 0;
}
