.container-map {
  height: 400px;
  width: 100%;
}

.map-container,
.map-ref {
  width: 100%;
  height: 100%;
}

#titulo-infowindow {
  font-size: 20px;
  font-weight: bold;
  color: #212121;
}

p {
  font: 400 14px "Source Sans Pro", sans-serif;
  color: #212121;
}

.adrress-mapa {
  margin-top: -12px;
}
.telefone-mapa {
  margin-top: -20px;
}
.email-mapa {
  margin-top: -12px;
  margin-bottom: 0px;
}

.gm-style .gm-style-iw-c {
  border: 2px solid #58c5c6;
  box-shadow: none;
  width: 290px;
}

.gm-style-iw-tc::after {
  background-color: #58c5c6 !important;
}

.gm-ui-hover-effect {
  right: 0px !important;
  top: 0px !important;
}
