@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

.MuiTypography-root {
  font-size: 0.9rem;
}

.MuiCardContent-root {
  padding: 16px 5px 16px 5px !important;
}

.prop-card {
  border: 2px solid #ebebeb;
  width: 320px;
  height: 420px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prop-card h2 {
  font-size: 20px;
  font-family: Source Sans Pro;
  font-weight: 700;
  padding-bottom: 30px;
}

.prop-card p {
  text-align: center;
  font-size: 14px;
}

.prop-card a {
  text-decoration: underline;
  color: #58c5c6;
  padding-top: 30px;
}
.prop-card a:hover {
  color: #17a2b8;
}

.acmarcas img {
  padding: 50px 0px 50px 0px;
  width: 157.85px;
}

.balcao-consumidor img {
  padding: 38px 0px 52px 0px;
  width: 131px;
}

.grupo-ativar img {
  padding: 52px 0px 55px 0px;
  width: 139px;
}

.grupo-ativar a {
  padding-top: 32px;
}

.consultas img {
  padding: 55px 0px 50px 0px;
  width: 147px;
}

.consultas a {
  padding-top: 30px;
}

.vitrine h2 {
  text-align: center;
  padding-bottom: 27px;
}

.vitrine img {
  padding: 45px 0px 48px 0px;
  width: 197px;
}

.accredito img {
  padding: 40px 0px 44px 0px;
  width: 112px;
}

.accredito h2 {
  padding-bottom: 35px;
}

.accredito a {
  padding-top: 48px;
}

.acsp-legal img {
  padding: 45px 0px 40px 0px;
  width: 184px;
}

.acsp-legal h2 {
  padding-bottom: 35px;
}

.acsp-legal a {
  padding-top: 30px;
}

.jucesp-serviço img {
  padding: 50px 0px 45px 0px;
  width: 197px;
}

.jucesp-serviço h2 {
  padding-bottom: 35px;
}

.receita-federal-serviço img {
  padding: 50px 0px 50px 0px;
  width: 191px;
}

.receita-federal-serviço h2 {
  padding-bottom: 35px;
}

.receita-federal-serviço a {
  padding-top: 30px;
}

.cemaac-serviço img {
  padding: 45px 0px 45px 0px;
  width: 160px;
}

.exclusividade-serviço img {
  padding: 45px 0px 45px 0px;
  width: 160px;
}

.exclusividade-serviço h2 {
  padding-bottom: 28px;
}

.exclusividade-serviço a {
  padding-top: 51px;
}
