@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

.saveCertificateContainer {
  margin: 25px;
}

.saveCertificateContainer h1:first-child {
  color: #092c43;
}

.saveCertificateContainer .back-icon {
  margin-right: 20px;
}

.saveCertificateContainer .operationButton {
  width: 97px;
  height: 36px;
  margin-right: 20px;
}

.saveCertificateContainer .inputFile h3 {
  font-size: 16px;
  font-weight: bolder;
}
.btnHoverOff:hover {
  background-color: rgba(255, 255, 0, 0.411);
}
.modalInactiveCertificate .modalButtonContainer {
  display: flex;
  justify-content: space-between;
}

.modalInactiveCertificate p {
  text-align: center;
}

.modalInactiveCertificate .modalButton {
  margin: 0 20px;
  width: 100px;
  height: 40px;
}

.error {
  color: #e71a14;
}

.saveCertificateContainer h1.title {
  font-size: 30px;
  font-weight: bold;
  margin: 30px 0;
}

.saveCertificateContainer {
  color: #707070;
}

.saveCertificateContainer .passwordField {
  margin-top: 30px;
  margin-bottom: 30px;
}

.labelmark {
  color: #f00;
  font-weight: bolder;
  margin-left: 5px;
}
.labelmark--backarrow {
  cursor: pointer;
}

#header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 576px) {
  #header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center !important;
  }
}

.yor_companyData {
  font-family: Source Sans Pro;
  font-weight: 600;
  color: #7b7b7b;
  font-size: 32px;
  text-align: center;
  margin: 75px 0px 30px 0px;
}

/* Borda do formulário */
/*
.companyData__form {
  border-style: solid;
  border-color: #d3d3d3;
  box-shadow: 3px 5px 10px #d3d3d3;
  border-width: 1px;
  border-radius: 8px;
  background-color: #fff;
} */

.generalData {
  font-family: Source Sans Pro;
  font-weight: 600;
  color: #7b7b7b;
  font-size: 24px;
}

.companyDatalabel {
  font-family: Source Sans Pro;
  font-weight: 600;
  color: #7b7b7b;
  font-size: 16px;
}

.companyData__label--1 {
  float: right;
}

.companyData__label--3 {
  margin-bottom: -10px;
}

.companyData__label--findCepLink {
  float: right;
  font-size: 12px;
  margin-top: 14px;
}

.companyData__label--modal {
  text-align: center;
}
