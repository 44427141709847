.border-payment {
    border-radius: 15px!important;
    border-style: solid!important;
    border-color: #64c2c8!important;
    border-width: 2px;
}

.font-radio, a {
    font-size: 0.9rem;
}

.option-address {
    font-size: 0.8rem;
    padding-left: 3rem;
}

.react-datepicker__day--disabled{
    text-decoration: line-through;
    color: red!important;
}

.react-datepicker__day--disabled:hover{
    cursor: not-allowed;
}

.react-datepicker-wrapper {
    display: flex;
    flex-grow: 1;
}


@media only screen and (max-width: 768px){
    #btn-consultancy {
        display: block;
        flex-direction: column!important;
        justify-content: center!important;
        text-align: center!important;
    } 
    #btn-consultancy * div  * p {
        text-align: center;
    }

    #btn-add-consultancy {
        margin-top: 1rem;
        display: flex!important;
        justify-content: flex-start!important;
    }
    .icon {
        display: none;
    }

    .header {
        display: flex;
        flex-direction: column; 
        justify-content: center;  
    }

    .header > p {
        padding: 0!important;
        margin: 0!important;
        text-align: center!important;
    }

    .btn-center {
        text-align: center!important;
    }
}