#icon :hover {
  cursor: pointer;
}

.invoice__form {
  border-radius: 10px;
  box-shadow: 0px 3px 8px #b1b1b1d1;
}

.invoice__btn {
  float: right;
}

.invoice__table--icon {
  cursor: pointer;
}

.invoice_product.form {
  border-style: solid;
  border-color: #d3d3d3;
  box-shadow: 3px 5px 10px #d3d3d3;
  border-width: 1px;
  border-radius: 8px;
  background-color: #fff;
  max-width: 90%;
}

.invoice_product.form .colorPrice {
  color: blue;
}

.card_border_radius {
  border-radius: 10px;
}

.card_bills_to_receive {
  background-color: #18c479;
}

.card_bills_to_pay {
  background-color: #e35b5a;
}

.card_revenues {
  background-color: #1f78b4;
}

.hide_line {
  display: flex !important;
}

.file_drag_and_drop {
  border-radius: 5px;
  border-style: dashed;
  width: 100%;
  height: 150px;
  color: transparent;
  border-color: #bbbbbb;
  background-color: #fafafa;

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
  transition: box-shadow 500ms;
}

.file_drag_and_drop:hover {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.397);

  transition: box-shadow 500ms;
  cursor: pointer;
}

@media only screen and (max-width: 769px) {
  .border-fade {
    border: none !important;
  }

  .hide_line {
    display: none !important;
  }
}

.imageRotate {
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 0.4s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
