.animation-down {
  animation: animationFrames linear 0.25s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
  animation-fill-mode: forwards; /*when the spec is finished*/
  -webkit-animation: animationFrames linear 0.25s;
  -webkit-animation-iteration-count: 1;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
  -moz-animation: animationFrames linear 0.25s;
  -moz-animation-iteration-count: 1;
  -moz-transform-origin: 50% 50%;
  -moz-animation-fill-mode: forwards; /*FF 5+*/
  -o-animation: animationFrames linear 0.25s;
  -o-animation-iteration-count: 1;
  -o-transform-origin: 50% 50%;
  -o-animation-fill-mode: forwards; /*Not implemented yet*/
  -ms-animation: animationFrames linear 0.25s;
  -ms-animation-iteration-count: 1;
  -ms-transform-origin: 50% 50%;
  -ms-animation-fill-mode: forwards; /*IE 10+*/
}

@keyframes animationFrames {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    transform: translate(1px, 0px) rotate(-90deg);
  }
}

@-moz-keyframes animationFrames {
  0% {
    -moz-transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    -moz-transform: translate(1px, 0px) rotate(-90deg);
  }
}

@-webkit-keyframes animationFrames {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(1px, 0px) rotate(-90deg);
  }
}

@-o-keyframes animationFrames {
  0% {
    -o-transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    -o-transform: translate(1px, 0px) rotate(-90deg);
  }
}

@-ms-keyframes animationFrames {
  0% {
    -ms-transform: translate(0px, 0px) rotate(0deg);
  }
  100% {
    -ms-transform: translate(1px, 0px) rotate(-90deg);
  }
}

.cardHeaderText {
  margin-top: 10px;
  font-weight: bold;
  color: #7b7b7b;
}

.btn-menu:hover {
  background-color: rgb(100, 194, 200);
}

.btn-menu:hover h3 {
  color: #fff;
}

.btn-menu:hover a {
  color: #fff !important;
}
