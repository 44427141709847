.icon-hover :hover{
    cursor: pointer;
}

@media (max-width: 768px) { 
    table, tr, td, th {
        display: flex!important;
        flex-direction: column;
        
    }

    table, tr, td, th * div {
        flex-grow: 1;
    }

    .search {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .action-icons {
        justify-content: end;
    }

    td {
        display: flex;
        flex-direction: row;
    }

    .max-rows-per-page {
        display: block;
        flex-grow: 1;
        flex-shrink: 1;
    }

    .pagination {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}
.provider__button--newProvider{
    background-color: rgb(100, 194, 200);
    border-color: rgb(100, 194, 200);
    font-family: "OpenSans";
    font-size: 14px;
    font-weight: bold;
    color: rgb(255, 255,255)
}

.provider__icon{
    color: rgb(135, 135, 135);
    cursor: pointer;
}

.provider__search--input{
    color: rgb(135, 135, 135, 0,5);
    font-size: 14px;
    border-color: transparent transparent rgb(135, 135, 135) transparent;
}

.provider__search--input--change{
    color: rgb(135, 135, 135);
}

.providerCreate__generalData__title{
    color: rgb(135, 135, 135);
    font-size: 18px;
    font-family: "OpenSans";
    font-weight: bold;
}

.prividerCreate__label{
    cursor: pointer;
}

.checkbox-round {
    width: 1.3em;
    height: 1.3em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 2px solid rgb(150, 150, 150);
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.checkbox-round:checked {
    background-color:  rgb(100, 194, 200);
}

.select {
    font-family: 'FontAwesome', 'Second Font name'
    }
    
.service .margin-left {
  margin-left: 10px;
}
.service.service_form {
  border-style: solid;
  border-color: #d3d3d3;
  box-shadow: 3px 5px 10px #d3d3d3;
  border-width: 1px;
  border-radius: 8px;
  background-color: #fff;
  max-width: 90%;
}

.service.service_form.container {
  height: 70vh;
}

.service.service_form .infoCNAE {
  color: #878787;
}


@media only screen and (max-width: 769px) {
    table, th, tr, td, tbody, thead {
        display: block;
    }
}

@media only screen and (max-width: 576px) {
    #table {
        display: flex!important;
        justify-content: center!important;
    }
}