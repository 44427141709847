.bg-light,
.espaçamento-perfil,
.logo-rodape-acsp {
  background-color: #fff !important;
}

.borda-divisoria {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #fff !important;
}

.imgSize {
  width: 160px;
}

.mobileCollapse {
  display: none;
}

.border-right {
  border: none !important;
}

@media (max-width: 1039px) {
  .mobileCollapse {
    display: block;
  }

  .drawerSidebar {
    display: none;
  }
}

@media (min-width: 1040px) {
  .grid {
    display: grid;
    grid-template-columns: 20vw 78vw;
    grid-template-rows: 10vh auto;
    grid-template-areas:
      "sidebar header"
      "sidebar content";
  }

  .drawerSidebar {
    grid-area: sidebar;
  }

  .header {
    grid-area: header;
  }

  #content {
    grid-area: content;
  }
}

.card-body {
  margin-left: 0 !important;
  padding: 0;
}
