.notifyContainer .infoNotify {
    display: flex;
    margin: 20px 0;
}

.notifyContainer .infoNotify img { 
    max-width: 36px; 
    max-height: 36px; 
    width: 36px; 
    height: 36px; 
    margin-top: 5px; 
  } 

.notifyContainer .titleNotify {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notifyContainer .titleNotify button {
    font-size: 20px;
}

.notifyContainer .infoStatus { 
    display: flex; 
    flex-direction: column; 
} 

.notifyContainer .infoStatus p { 
    margin-bottom: 0; 
    margin-left: 10px; 
    max-width: 270px; 
} 

.notifyContainer .notifyTime { 
    display: flex;  
    align-self: flex-end; 
} 

.notifyContainer .notifyTime p { 
    margin-bottom: 0; 
} 

.popoverSize { 
    max-width: 100%; 
    width: 430px; 
    padding: 12px 12px; 
} 

.infoNotify .linkCard {
    text-decoration: none;
}

.infoNotify .linkCard p{
    color: rgb(100, 194, 200);
    border-bottom: 1px solid rgb(100, 194, 200);
    max-width: 80px;
}
