p {
  color: rgba(135, 135, 135, 1);
  font-size: 16px;
}

button {
  border: none;
  background: none;
  outline: none;
}

.container {
  margin-left: 10px;
}

.container .reception {
  font-size: 18px;
}

.info {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

.info h3 {
  font-size: 34px;
  font-weight: bold;
}

.info .button {
  background: white;
  height: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 17px;
  box-shadow: 0px 3px 8px #b1b1b1d1;
  text-decoration: none;
}

.info .button p {
  margin: auto 0 auto 30px;
  margin-left: 13px;
  color: black;
  font-weight: bold;
}

.info .button img {
  margin-left: 15px;
  width: 16px;
}

.info .button .bi-chevron-right {
  margin: auto 0;
  margin-right: 20px;
  color: black;
}

.container .card {
  background: white;
  border-radius: 20px;
  /* box-shadow: 0px 3px 8px #b1b1b1d1; */
  border: 2px solid #ebebeb;
  display: flex;
  flex-direction: column;

  padding: 10px 30px;
  margin-top: 10px;
}

.container .card h4 {
  font-size: 18px;
  padding-bottom: 10px;
}

.container .card img {
  width: 200px;
  margin: 10px auto;
}

.container .linkCard {
  display: flex;
  align-self: flex-end;
  text-decoration: none;
}

.container .linkCard p {
  color: rgb(100, 194, 200);
  margin-left: 15px;
  margin-top: 10px;
  border-bottom: 1px solid rgb(100, 194, 200);
}

.card .cardInfo {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid rgba(135, 135, 135, 0.3);
  margin: auto 0;
  margin-top: 7px;
  align-items: center;
  text-decoration: none;
  max-height: 40px;
}

.green {
  color: rgb(0, 179, 95);
}

.yellow {
  color: rgb(247, 182, 0);
}

.card .cardInfo .status i {
  margin-left: 30px;
  color: black;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.align-end {
  align-items: flex-end;
}

.flex p {
  margin: auto 10px;
}

.section:not(:last-child) {
  margin-top: 30px;
  padding-bottom: 40px;
  /* border-bottom: 2px solid rgba(135, 135, 135, 0.3); */
  border-bottom: 2px solid #ebebeb;
}

.card .cardInfo img {
  width: 30px;
}

.card .titleChart {
  display: flex;
  justify-content: space-between;
}

.card .titleChart h5 {
  border: none;
}

.section .playVideoImg {
  height: 100px;
}

.section .videoCard .videoTitle {
  font-size: 14px;
  color: black;
}

.section .videoCard .videoTime {
  font-size: 10px;
}
