.certificateListContainer {
  margin-top: 30px;
  max-width: 1300px;
  background: white;
  border-radius: 20px;
  /* box-shadow: 0px 3px 8px #b1b1b1d1; */
  border: 2px solid #ebebeb;
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  margin-top: 10px;
  font-size: 14px;
}

.certificateListContainer th {
  border-top: none;
}

.certificateListContainer p {
  font-weight: bolder;
  font-size: 32px;
  font-family: Source Sans Pro;
  color: #212121;
}

.table thead th {
  border-bottom: 1px solid #e7e7e7;
}

.table td {
  border: none;
  vertical-align: middle;
}

.cert-active {
  width: 90px;
  height: 66px;
  display: flex;
  justify-content: space-between;
  color: #0cba90;
  align-items: center;
}

.cert-active p {
  font-size: 14px;
  color: #0cba90;
  font-weight: 600;
  margin: 0px !important;
}

.certificateListContainer .cert-inactive {
  color: #e71a14;
}

.modal-content {
  border-radius: 20px;
}

.modal-header {
  border: none;
  padding: 20px 28px 0px 0px;
}

.modal-header .close {
  font-size: 32px;
}

.modalDeleteCertificate .modalButtonContainer {
  display: flex;
  justify-content: center;
}

.modalDeleteCertificate .modalBody p {
  font-size: 24px;
  text-align: center;
  color: #d70f57;
  font-family: Source Sans Pro;
  margin: -20px 0px 48px 0px;
  line-height: 28px;
}

.modalDeleteCertificate .modalButtonContainer .modalButton {
  width: 140px;
  height: 42px;
}

.btn-nao {
  background-color: #7b7b7b;
  border: none;
  margin-right: 15px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
}

.btn-nao:hover {
  color: #7b7b7b;
  background-color: #fff;
  border: 1px solid #7b7b7b;
}

.btn-sim {
  background-color: #d70f57;
  color: #fff;
  border: none;
  margin-left: 15px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
}

.btn-sim:hover {
  background-color: #fff;
  color: #d70f57;
  border: 1px solid #d70f57;
}

.modalPasswordContainer .modal-content {
  color: #878787;
  border-radius: 15px;
}
.modalPasswordContainer .modalPassword {
  margin: 10px;
}

.modalPasswordContainer .modalPassword .inputChangePassword {
  margin-top: 20px;
  margin-bottom: 20px;
}

.container-inputChangePassword {
  padding-left: 60px;
}

.container-btn-password {
  display: flex;
  justify-content: center;
}

.btn-salvar {
  width: 140px;
  height: 42px;
  background-color: #58c5c6;
  color: #fff;
  border: none;
  margin: 20px 0px 10px 10px;
  font-size: 16px;
  font-weight: 600;
}

.btn-salvar:hover {
  background-color: #fff;
  color: #58c5c6;
  border: 1px solid #58c5c6;
}

.btn-cancelar {
  width: 140px;
  height: 42px;
  background-color: #7b7b7b;
  color: #fff;
  border: none;
  margin: 20px 10px 10px 0px;
  font-size: 16px;
  font-weight: 600;
}

.btn-cancelar:hover {
  background-color: #fff;
  color: #7b7b7b;
  border: 1px solid #7b7b7b;
}

.modalPasswordContainer .modalPassword h4 {
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  margin: -20px 0px 35px 0px;
  color: #212121;
}
