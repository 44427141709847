@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap");

h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #212121;
}

.modalContentDAS .modalBody {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.modalContentDAS .modalBody label p {
  margin: 0;
  margin-top: 15px;
  font-size: 16px;
}

.modalContentDAS .modalFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modalContentDAS .modalFooter .showPdf {
  width: 200px;
}

.card .DASTitle {
  padding-bottom: 7px;
  border-bottom: 2px solid rgba(135, 135, 135, 0.3);
}

/* .card-dasn {
  width: 80%;
} */
