.userInfo {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.userHeader {
    border-bottom: 2px solid rgba(135,135,135,0.3);
}

.userInfo p{
    font-size: 12px;
}

.userBody {
    margin-top: 20px;
}