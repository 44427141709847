.inputFileContainer .fileInput {
  width: 10px;
  border: none;
  background: none;
  visibility: hidden;
}

.inputFileContainer {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.inputFileContainer .inputButton {
  display: flex;
  border: 1px solid #ececec;
  border-radius: 5px;
  width: 320px;
  height: 48px;
  background-color: #58c5c6;
  justify-content: center;
  align-items: center;
  padding-top: 0.5rem;
  cursor: pointer;
}

.inputFileContainer .inputButton:hover {
  background-color: #fff;
  border: 1px solid #58c5c6;
}

.inputFileContainer .inputButton:hover label {
  color: #58c5c6;
}

.inputFileContainer .inputButton label {
  /* font-weight: bold; */
  /* margin-left: 30px; */
  /* margin-top: 10px; */
  /* align-self: center; */
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}

/* .inputFileContainer .inputButton .backIcon {
  margin-left: 20px;
} */

.inputFileContainer .fileName {
  font-size: 12px;
  color: #d70f57;
  /* color: #878787; */
  /* margin-left: 20px; */
}
